import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import useLocalisation from '../helper/useLocalisation'
import {
  Banner,
  BannerText,
  BodyText,
  CenterText,
  ContentPadding,
  CreamBackground,
  Heading,
  ImageContainer,
  SplitContainer,
  SplitItem,
  StaticContainer,
  StyledImage,
  TopMagin,
  VerticalSplit,
  VerticalSplitItem,
} from '../styles/static.js'

const WhyPage = ({ data }) => {
  const stack = data.Comfort.childImageSharp.gatsbyImageData
  const laundry = data.Laundry.childImageSharp.gatsbyImageData
  const shoot1 = data.Shoot1.childImageSharp.gatsbyImageData
  const shoot2 = data.Shoot2.childImageSharp.gatsbyImageData
  const Q = useLocalisation()
  return (
    <Layout>
      <SEO
        title={'Why July'}
        description={
          'We exist to bring better design and thinking around how we travel. To elevate your travel standard from product-buying to being augmented around the experience of considered design and service.'
        }
      />
      <StaticContainer>
        <TopMagin />
        <CreamBackground>
          <ContentPadding>
            <SplitContainer center={true}>
              <SplitItem>
                <ImageContainer>
                  <StyledImage image={stack} />
                </ImageContainer>
              </SplitItem>
              <SplitItem>
                <Heading main={true}> {Q('nav-why-july')} </Heading>
                <br />
                <BodyText>{Q('why-july-subheading')}</BodyText>
              </SplitItem>
            </SplitContainer>
          </ContentPadding>
        </CreamBackground>
        <Banner>
          <BannerText> {Q('nothing-to-lose')} </BannerText>
        </Banner>
        <ContentPadding>
          <SplitContainer>
            <SplitItem noPadding={true}>
              <VerticalSplit>
                <VerticalSplitItem>
                  <Heading> {Q('considered-design')} </Heading>
                  <BodyText>{Q('considered-subheading')}</BodyText>
                </VerticalSplitItem>
                <VerticalSplitItem>
                  <ImageContainer>
                    <StyledImage image={shoot1} />
                  </ImageContainer>
                </VerticalSplitItem>
              </VerticalSplit>
            </SplitItem>
            <SplitItem noPadding={true}>
              <VerticalSplit>
                <VerticalSplitItem>
                  <ImageContainer>
                    <StyledImage image={laundry} />
                  </ImageContainer>
                </VerticalSplitItem>
                <VerticalSplitItem>
                  <Heading> {Q('seamless-travel')} </Heading>
                  <BodyText>{Q('seamless-subheading')}</BodyText>
                </VerticalSplitItem>
              </VerticalSplit>
            </SplitItem>
          </SplitContainer>
        </ContentPadding>
        <CreamBackground bottom={true}>
          <ContentPadding>
            <SplitContainer>
              <SplitItem>
                <Heading> {Q('considered-features')} </Heading>
                <BodyText>{Q('considered-features-subheading')}</BodyText>
              </SplitItem>
              <SplitItem>
                <ImageContainer>
                  <StyledImage image={shoot2} />
                </ImageContainer>
              </SplitItem>
            </SplitContainer>
            <CenterText>
              <span style={{ textAlign: 'center' }}>
                {/* <Quotation>''</Quotation> */}
                <span> {Q('creating-sustainable')} </span>
                {/* <Quotation>''</Quotation> */}
              </span>
            </CenterText>
          </ContentPadding>
        </CreamBackground>
      </StaticContainer>
    </Layout>
  )
}

export default WhyPage

export const query = graphql`
  {
    Comfort: file(relativePath: { eq: "buy/comfort.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    Laundry: file(relativePath: { eq: "buy/laundry.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    Shoot1: file(relativePath: { eq: "why/shoot1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    Shoot2: file(relativePath: { eq: "why/shoot2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
`
